// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	
	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}
	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//fix height page
		function fixBottom(){
			var dh=$(document).outerHeight();
			//var hh=$('#site_header').outerHeight();
			var mh=$('#site_main').outerHeight();
			var fh=$('#site_footer').outerHeight();
			var diff_h=$('body').innerHeight()-$('body').height();
			if(dh > $('body').outerHeight()){
				//$('#site_main').outerHeight(dh-fh-diff_h-1);
			}
		}
		setTimeout(function(){
			fixBottom();
		},1000);
		
		//menu mobile
		
		$('#navbar-collapse-categories-mobile [data-toggle="collapse"]').on('click',function(e){
			e.stopPropagation();
			if(!$(this).hasClass('collapsed')){
				e.preventDefault();
				$(this).addClass('collapsed');
				$(this).next().removeClass('in');
			}
			
		});
		
		
		//DISABLE PARALLAX FOR SAFARI
		var is_safari = (navigator.userAgent.indexOf('Safari') != -1) && (navigator.userAgent.indexOf('Chrome') == -1);
		if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) || is_safari) {
			$(".dropdown-toggle").mouseover(function(e) {
          e.stopPropagation();
			});
		}
		
		//First modal registration
		$( '#registrationModalThanks').modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		
		// Animated Hamburger Icon, Open and Close navMobile
		$('#nav-icon').click(function(e){
			$('#navbar-collapse-categories-mobile').click(); //Element clicked to trigger the below function that will close the menu automatically when a user clicks outside of it
			e.preventDefault();
			e.stopPropagation();
			$(this).toggleClass('open');
			//$('.main-logo-container').toggleClass('open');
			
			var menuStatus = $('.navMobile').css('left');
			if(menuStatus=='-285px')
			{
				/*open*/
				$('.navMobile').animate({
					'left':'0'
				},'fast');
				$('.main-logo-container-fixed').animate({
					'left':'65px',
					'margin-left':'0',
					'opacity':'1'
				},'fast');
				$('.main-logo-container>a>img.main-logo').animate({
					'margin-left': '-134px',
					'opacity':'0'
				},'fast');
			}
			else
			{
				/*close*/
				$('.navMobile').animate({
					'left':'-285px'
				},'fast');
				$('.main-logo-container-fixed').animate({
					'left':'50%',
					'margin-left':'-48px',
					'opacity':'0'
				},'fast');
				$('.main-logo-container>a>img.main-logo').animate({
					'margin-left': '0',
					'opacity':'1'
				},'fast');
			}
		});
		
		$('#nav-icon-close').click(function(e){
			e.preventDefault();
			e.stopPropagation();
			$('#nav-icon').removeClass('open');
			//$('.main-logo-container').toggleClass('open');
			var menuStatus = $('.navMobile').css('left');

			if(menuStatus=='0px')
			{
				$('.navMobile').animate({
					'left':'-285px'
				},'fast');
				$('.main-logo-container-fixed').animate({
					'left':'50%',
					'margin-left':'-48px',
					'opacity':'0'
				},'fast');
				$('.main-logo-container>a>img.main-logo').animate({
					'margin-left': '0',
					'opacity':'1'
				},'fast');
			}
			else
			{
				$('.navMobile').animate({
					'left':'0'
				},'fast');
				$('.main-logo-container-fixed').animate({
					'left':'65px',
					'margin-left':'0',
					'opacity':'1'
				},'fast');
				$('.main-logo-container>a>img.main-logo').animate({
					'margin-left': '-134px',
					'opacity':'0'
				},'fast');
			}
		});
		//
		
		
		
		// Code to close automatically the mobile menu when the user clicks outside of it
		$(document).mouseup(function(e) 
		{
			var valueLeftNavMobile = $('[data-zg-role="navMobile"]').css('left');
				
			// It will only be closed when the navMobile is opened
			if( valueLeftNavMobile == "0px" ){
					var container = $('#navbar-collapse-categories-mobile');

					// if the target of the click isn't the container nor a descendant of the container
					if (!container.is(e.target) && container.has(e.target).length === 0) 
					{
							$('#nav-icon-close').click();
					}
			}
		});
		
		
		
		// navMobile scroll event
		$( '.navMobile' ).scroll(function() {
			var scrollPos = $('.navMobile').scrollTop();
			if (scrollPos > 1){  
				$('.main-logo-container-fixed').removeClass("visible-xs");
				$('.main-logo-container-fixed').addClass("hidden");
				$('#nav-icon-close').css("display","none");
			}
			else{
				$('.main-logo-container-fixed').removeClass("hidden");
				$('.main-logo-container-fixed').addClass("visible-xs");
				$('#nav-icon-close').css("display","block");
			}	
		});
		
		
		// stiky header
		$(window).scroll(function() {
			var scrollPos = $(document).scrollTop();
			if (scrollPos > 1){  
				$('body.page_home').addClass("sticky");
				$('a.dropdown-toggle').removeClass("white");
			}
			else{
				$('body.page_home').removeClass("sticky");
				$('a.dropdown-toggle').addClass("white");
			}	
		});
		
		// Open close tab-pane mobile
		$('.closeTab').click(function(e){
			$('.tabbable .nav li').removeClass('active');
			$('.tabbable .tab-content .tab-pane').removeClass('active');
		});
		
		// Toggle div for address in Account manager
		$(document).on("click", ".openAddress" , function() {
			$(".closeTab").css('display','none!important')
			$(".dynAddress").css('display','block');
			$(".addressList").css('display','none');
		});
		$(document).on("click", ".dynAddress .btn-back" , function() {
			$(".closeTab").css('display','block')
			$(".dynAddress").css('display','none');
			$(".addressList").css('display','block');
		});
		
		
		// Reset form
		$("#resetForm").click(function(){ 
			$("#addressFormNoModal")[0].reset();
		});
		
		// Select Refund/Change product, get the value from a checked checkbox
		$(".inputCheckbox").click( function(){
			var elementId = $(this).attr('id').slice(-1);
			var selectOpt = ("select.sel-" + elementId);
			
			if( $(this).is(':checked') ){
				if ( $(this).val() == 1 ){
					//refund
					$( selectOpt ).val("refund").change();
				} else {
					//exchange
					$( selectOpt ).val("exchange").change();
				}
			} else {
				$( selectOpt ).val("").change();
			}
		});
		
	
	// Carousel Settings - Home
	$( '.carousel' ).carousel( {} );
			// SWIPE CAROUSEL
			var $carousel = $( '.carousel' );
			if ( $carousel.length ) {
					$carousel.swiperight( function () {
							$( this ).carousel( 'prev' );
					} );
					$carousel.swipeleft( function () {
							$( this ).carousel( 'next' );
					} );
			}
	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function (e) {
			e.preventDefault();
			var target = $(this).data('zg-target');
			var top=$(target).offset().top;
			if($('.manager_category').length !==0 || $('.manager_product').length !==0){
		
				top=top-100;
			}
			if($('.manager_home').length !==0){
				top=0;
			}
			console.log(top);
			
			//href = $(this).attr("href");
			$('html, body').animate({scrollTop: top}, 1000);
			//window.location.hash = href;
		});
		
	});
	
		
	
	//START: CUSTOM AFFIX CODE
	//The idea is to set fixed the $productFixedContainer when this element reaches the top of the page. 
	//It will be set as relative when $relatedProducts shows up from the bottom of the screen (when the user scrolls down) and set back fixed if the user scrolls up again.
	//Code to check if an element is visible in viewport taken from here: https://stackoverflow.com/questions/20791374/jquery-check-if-element-is-visible-in-viewport/33979503#33979503
	$(window).on('load', function() {
		$('.load-after').removeClass('load-after');
	if(!IS_MOBILE){
		$( function () {
			setTimeout(function(){
				$( "[data-zg-role='product-options-fixed']" ).each(function() {
					var $productFixedContainer = $(this);//$( "[data-zg-role='product-options-fixed']" );
					//$productFixedContainer.css('width',$productFixedContainer.outerWidth());
					var windowScrollTop = 0;
					var $relatedProducts = $("#banner-product");
					if($("#banner-product").length==0){
						$relatedProducts = $("#site_footer");
					}

					//The purpose of this "flag" is to only set only once the CSS top value to the fixedContainer only when $relatedProducts shows up from the bottom of the page.
					//If the user scrolls up and $relatedProducts dissapears again, then, the flag is enabled again

					if( $productFixedContainer.length ){
						$productFixedContainer.innerWidth($productFixedContainer.width());
						$(window).scroll(function(e){
							windowScrollTop = $(window).scrollTop();
							var maxScrollTop =$relatedProducts.offset().top - $productFixedContainer.outerHeight()-300;
							
							if (windowScrollTop <= maxScrollTop){
									$productFixedContainer.css( 'position', 'fixed' );
									$productFixedContainer.css( 'top', '' );
							}else{
								$productFixedContainer.css( 'position', 'relative' );
								$productFixedContainer.css( 'top', maxScrollTop );
							}

						});
					}
				});
			},500);
		});
	}
	});
	/*$( function () {
		setTimeout(function(){
 		$( "[data-zg-role='product-options-fixed']" ).each(function() {
		var $productFixedContainer = $(this);//$( "[data-zg-role='product-options-fixed']" );
		$productFixedContainer.css('width',$productFixedContainer.outerWidth());
		$( window ).resize(function() {
			$productFixedContainer.css('width', 'auto');
			$productFixedContainer.css('position', 'relative');			
			$productFixedContainer.css('width',$productFixedContainer.outerWidth());
			$productFixedContainer.css('position', 'fixed');
		});
		var windowScrollTop = 0;
		var heightHeader = $productFixedContainer.offset().top;//94;
		var $relatedProducts = $("#related-products");

		//The purpose of this "flag" is to only set only once the CSS top value to the fixedContainer only when $relatedProducts shows up from the bottom of the page.
		//If the user scrolls up and $relatedProducts dissapears again, then, the flag is enabled again
		var flag = true; 

		if( $productFixedContainer.length ){
			$(window).scroll(function(e){
				var windowScrollTop = $(window).scrollTop();
				//console.log(windowScrollTop);
				if( windowScrollTop > heightHeader ){
					$productFixedContainer.addClass( 'custom-affix' );
				}else{
					$productFixedContainer.removeClass( 'custom-affix' );
				}

				var extraPaddingAdded = 0; //Value modified according to the space that needs to be added in the top of the YMAL products so that the Affix feature will work properly
				var top_of_element = $relatedProducts.offset().top - extraPaddingAdded;
				var bottom_of_element = $relatedProducts.offset().top + $(window).innerHeight() + $relatedProducts.outerHeight();// + $productFixedContainer.outerHeight();
				var top_of_screen = windowScrollTop;
				var bottom_of_screen = windowScrollTop + $(window).innerHeight();

				if ((bottom_of_screen > top_of_element) && (top_of_screen <= bottom_of_element)){
					// the element is visible
					//console.log("visible");
					if( flag ){
						$productFixedContainer.css( 'position', 'relative' );
						$productFixedContainer.css( 'top', windowScrollTop );
						flag = false;
					}
				} else {
					// the element is not visible
					//console.log("invisible");
					$productFixedContainer.css( 'position', 'fixed' );
					if( flag ){
						console.log(flag);
						$productFixedContainer.css( 'top', heightHeader );
					}

					if( top_of_element > bottom_of_screen ){
						flag = true;
					}
				}
				//console.log(flag);
			});
		}
	});
		},400);
	});*/
	//END: CUSTOM AFFIX CODE
		

	
	$(window).scroll(function(e){
		var $el = $('.onTop'); 
		if ($(this).scrollTop() > 300){ 
			$el.removeClass('hidden'); 
		}
		if ($(this).scrollTop() < 300)
		{
			$el.addClass('hidden'); 
		} 
		if($('.product-bar').length){
			var $bar = $('.product-bar'); 
			if ($(this).scrollTop() > 300){ 
				$bar.addClass('up'); 
			}
			if ($(this).scrollTop() < 300)
			{
				$bar.removeClass('up'); 
			}
		}
		if($('.checkout-fixed').length){
			var $barcheckout = $('.checkout-fixed'); 
			if ($(this).scrollTop() > 100){ 
				$barcheckout.addClass('up'); 
			}
			if ($(this).scrollTop() < 100)
			{
				$barcheckout.removeClass('up'); 
			}
		}
		/*if($(this).scrollTop() > ($(document).innerHeight()/1.4 )){
			$('.product-bar').addClass('top');
		}else{
			$('.product-bar').removeClass('top');
		}*/
	});
	

	// Mobile header scroll up and down
	$(function(){
			//Keep track of last scroll
			var lastScroll = 0;
			$(window).scroll(function(event){
					//Sets the current scroll position
					var st = $(this).scrollTop();
					//Determines up-or-down scrolling
					if (st > lastScroll){
						$(".headerMobile").removeClass("fixed");
						//$(".manager_account #site_main").removeClass("paddingTop");
					} 
					else {
						$(".headerMobile").addClass("fixed");
						//$(".manager_account #site_main").addClass("paddingTop");
					}
					//Updates scroll position
					lastScroll = st;
			});
   });
	
	
	$('.input_email').on( "focus", function(){
		$('.newsletter_block .input-group-btn').removeClass('hidden');
		$('.newsletter_block .checkbox').removeClass('hidden');
	});
	
	//STICKY HEADER CHANGE BG
	var $stickyHeader=$('#site_header');
	var main_banner=false;
	
	// SET BANNER 100% HEIGHT
	function setImageBanner(){
		var $docHeight =  $( window ).height();
		$('.main_banner').css('height',$docHeight);
		
	}
	if($('.main_banner').length){
		setImageBanner();
		main_banner=true;
	}
	function mainBanner(){
		if(main_banner){			
			var sticky = $('.main_banner').height();//+$stickyHeader.height();
			 if (window.pageYOffset > sticky) {
					$stickyHeader.addClass('white');
			}else{
				$stickyHeader.removeClass('white');
			}
		}else{
			if (window.pageYOffset > 0) {
				$stickyHeader.addClass('white');
			}else{
				$stickyHeader.removeClass('white');
			}
		}
	}
	$(window).scroll(function() {
		mainBanner();
	});
	
	$( window ).resize(function() {
		if($('.main_banner').length){
			setImageBanner();
		}
		
	});
	$(document).ready(function(){
			mainBanner();
	});
	$('#typeahead-search-button').on('click',function(e){
		if($('#product-finder .close').length){
				e.preventDefault();
				$('#product-finder .close').removeClass('close');
		}
	});
	// SEARCH MOBILE
	$('#typeahead-search-button-mobile').on('click',function(e){
		if($('#product-finder .close').length){
				e.preventDefault();
				$('#product-finder .close').removeClass('close');
		}
	});
	$('#searchToggle').on('click',function(e){
		$('.headerMobile').toggleClass('bkgWhite');							
		$('#searchForm').toggle('slow', function() {
			// complete
		});									
	});
	$( document ).on('[data-zg-role="reset-filters"]','click', function(){
			$( document ).trigger('zg.filter.applyFilters');
	});
	//ON CART LOAD AND WISH
	$( document ).on( 'zg.getProductList.updated',function(){
		//console.log($('[data-zg-collapse="collapse"]').length);
		$('[data-zg-collapse="collapse"]').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			$(this).addClass('hidden')
			$('.'+$(this).attr('data-zg-target')).removeClass('hidden');
		});
	});
})( jQuery );
// -----------------------------------------------------------------------------
// STORE LOCATOR LIST
// HIDE RESULTS IF NO FILTERS ARE APPLIED

(function ($) {
    'use strict';
	$(document).on('click','#sl-container [data-zg-role="reset-filters"]',function(e){
		setTimeout(function(){
			$( document ).trigger( 'storeReset');
		},800);
	});
	$(document).on('focus','#store-locator [data-zg-role="filter-search"]',function(e){
		$('[data-zg-role="filter-search"]').val('')
	});

    $(function () {
        var $filtersStores = $('#sl-filter-container');
        var $paginationStores = $('#sl-stores-container');

        $filtersStores.on('applyFilters', function (e, appliedFilters) {
            if (Object.keys(appliedFilters).length >= 1 ||  $('[data-zg-role="filter-search"]').val().length !=0) {
                $paginationStores.removeClass('hidden');
            } else {
                $paginationStores.addClass('hidden');
            }
        });
    });
})(jQuery);

$( document ).on( 'applyFilters',function(){
	//console.log("applyFilters");
	if($('[data-zg-role="selected-filter"]').length){
		$( '[data-zg-role="selected-filter"]' ).text('');
		$('#filter-container .content-filter .active').each(function(){
			if($(this).data('zg-action')!== "reset"){
				var $this=$(this).clone();
				var $t=$(this);
				$( '[data-zg-role="selected-filter"]' ).append( $this );
				$this.on('click',function(e){
					e.preventDefault();
					e.stopPropagation();
					$t.click();
				});
			}
		});
		// data-role="rangeslider" #range-1a #range-1b (min max value)
		if(
			$('[data-role="rangeslider"] #range-1a').attr('min') !==  $('[data-role="rangeslider"] #range-1a').attr('value') || 
			$('[data-role="rangeslider"] #range-1b').attr('max') !==  $('[data-role="rangeslider"] #range-1b').attr('value') 
			){
				/*var ram = $('[data-role="rangeslider"] #range-1a').attr('min'),
				rav = $('[data-role="rangeslider"] #range-1a').attr('value'),
				rbm = $('[data-role="rangeslider"] #range-1b').attr('max'),
				rbv = $('[data-role="rangeslider"] #range-1b').attr('value');
				var min = ram !== rav ? rav : ram;
				var max = rbm !== rbv ? rbv : rbm;
				console.log(min);
				console.log(max);
				*/
				var $filterPrice='<a class="txt-filter price-filter">'+$('[data-role="rangeslider"] #range-1a').attr('value')+' - '+$('[data-role="rangeslider"] #range-1b').attr('value')+'</a>' ;
				$( '[data-zg-role="selected-filter"]' ).append( $filterPrice );
				$('.price-filter').on('click',function(){					
					$('[data-role="rangeslider"]').trigger('reset');
				});
				$('.selected-filter').removeClass('hidden');
				var $delete='<div class="visible-xs removeAllFilters pull-right">x</div>';				
				$( '[data-zg-role="selected-filter"]' ).append( $delete );
				$('.removeAllFilters').on('click',function(){
					$('.selected-filter a').each(function(){
						$(this).trigger('click');
					});
				});
		}else{
			if($('#filter-container .filter.panel .content-filter .active').not('[data-zg-action="reset"]').length===0){
				$('.selected-filter').addClass('hidden');
			}else{
				$('.selected-filter').removeClass('hidden');
				var $delete='<div class="visible-xs removeAllFilters pull-right">x</div>';
				$( '[data-zg-role="selected-filter"]' ).append( $delete );
				$('.removeAllFilters').on('click',function(){
					$('.selected-filter a').each(function(){
						$(this).trigger('click');
					});
				});
			}			
		}
	}else{
		$('.selected-filter').addClass('hidden');				
	}
	
});

/*to close the options when click out*/
$(document).mouseup(function (e) {
   if (!$('.product-filters').is(e.target) // if the target of the click isn't the container...
   && $('.product-filters').has(e.target).length === 0) // ... nor a descendant of the container
   {
     $('.product-filters').parent('.btn-group-toggle').find('[data-zg-role="selected-single-combination"]').not('.collapsed').click();
  }
 });
//MINIBAR AND main option on product page LINKED
$('.manager_product').on('click','.product-options [data-value]',function(e, isTriggered){
	//ADD THE CHANGE OF BIGZOOM
	$('[data-zg-role="thumbnail-zoom"]').first().addClass('selected');
	var selectedBkg = $('[data-zg-role="thumbnail-zoom"]').first().attr('data-zoom-images');
	$('[data-zg-role="big-zoom"]').css('background-image', 'url('+ selectedBkg +')');
	//CHANGE MINIBAR IMAGE
	$('.product-bar .img-responsive').attr('src',selectedBkg.replace('_big','_cart'));
	
	//END
	if (!isTriggered){
		var $t=$(this);
		var val=$(this).attr('data-value');
		$('.product-options [data-value="'+val+'"]').not($(this)).each(function(){
			if($(this)!==$t && !$(this).hasClass('active')){
				$(this).trigger('click',[true]);
			}
		});
	}
	
});

$( function () {	
	if ( !$( '#modalSizeChart' ).length )
		return false;

	function loadSizechart ( categories,id ) {
		var $container = $('#modalSizeChart .modal-body');
		var	sizechartfilter = categories || 0;
		var	product_id = id || 0;


		var	url = window.makeUrl( { module: 'eshop', manager: 'cms', action: 'list', type: 'new_size_guide', master : 'master_blank' } );

		if ( categories || id ) {
			
			url = window.makeUrl( { module: 'eshop', manager: 'cms', action: 'list', type: 'new_size_guide', sizechartFilterBy: sizechartfilter , productId: product_id , master : 'master_blank' } );
		}

		$.ajax({
			url: url,
			cache: false,
			dataType: "html",

			beforeSend: function ( xhr ) {
				xhr.setRequestHeader('X-Requested-With', 'empty');
				$container.empty().addClass('loading-secondary');
			},

			success: function ( responseText ) {
				$container.empty().append( responseText );
			},

			complete: function () {
				$(document).trigger('sizechart-modal');
				$container.removeClass('loading-secondary');
			}
		});
	}

	$( document ).on( 'click', '[data-sizechart-categories]', function(e){
		var categories = $(this).data('sizechart-categories');
		var id = $(this).data('sizechart-id');
		loadSizechart( categories, id );
	});
	
	//CM INCH SELECTOR
	$(document).on('click','[data-prop-selector]',function(){
		//DESELECT ALL
		$('[data-prop-selector]').removeClass('active');
		//SELECT CLICKED
		$(this).addClass('active');
		//HIDE ALL OPTIONS
		$('[data-prop]').addClass('hidden');
		//SHOW SELECTED OPTION
		$('[data-prop='+$(this).data('prop-selector')+']').removeClass('hidden');
	});
	
	//SIZE SELECTOR
	$(document).on('click','[data-size-selector]',function(){
		//DESELECT ALL
		$('[data-size-selector]').removeClass('active');
		//SELECT CLICKED
		$(this).addClass('active');
		//HIDE ALL OPTIONS
		$('[data-size]').addClass('hidden');
		//SHOW SELECTED OPTION
		$('[data-size='+$(this).data('size-selector')+']').removeClass('hidden');
	});
	
	//HIDE SIZE DISABLED
	$(document).on('sizechart-modal',function(){
		$('#modalSizeChart .right-box [data-size-selector]').addClass('hidden');
		$('#site_main > .product-details [data-option-id="2"] a').each(function(){
			var s=$(this).html().trim();
			//console.log($(this).html().trim());
			$('#modalSizeChart .right-box [data-size-selector="'+s+'"]').removeClass('hidden');
			//$(this).data('size-selector');
		});	
	});


	// Swap Show more/less button text
	$( '[data-zg-role="show-more-less"]').click( function( e ){
		if( $( this ).hasClass( 'collapsed') ){
			$( this ).find( '[data-zg-role="show-more-btn"]' ).addClass( 'hidden' );
			$( this ).find( '[data-zg-role="show-less-btn"]' ).removeClass( 'hidden' );
		}
		else {
			$( this ).find( '[data-zg-role="show-more-btn"]' ).removeClass( 'hidden' );
			$( this ).find( '[data-zg-role="show-less-btn"]' ).addClass( 'hidden' );
		}
	});

} );



// register page
if ( $('[data-zg-role="register-area"]').length ) {
	$('[data-zg-role="register-area"]').hide();
	$('[data-zg-role="guest-area"]').hide();
	$(document).on("click",'[data-zg-role="register-btn"]',function(e) {
			e.preventDefault();
			$('[data-zg-role="login-area"]').hide();
			$('[data-zg-role="register-area"]').show();
	});

	$(document).on("click",'[data-zg-role="guest-btn"]',function(e) {
			e.preventDefault();
			$('[data-zg-role="login-area"]').hide();
			$('[data-zg-role="guest-area"]').show();
	});

	$(document).on("click",'[data-zg-role="back-register"]',function(e) {
			e.preventDefault();
			$('[data-zg-role="login-area"]').show();
			$('[data-zg-role="register-area"]').hide();
	});

	$(document).on("click",'[data-zg-role="back-guest"]',function(e) {
			e.preventDefault();
			$('[data-zg-role="login-area"]').show();
			$('[data-zg-role="guest-area"]').hide();
	});
}

if ( $('.input-group input,.input-group textarea,.input-group select').length ) {
	
	$( ".input-group input,.input-group textarea,.input-group select" ).each(function( index ) {
 		if($(this).val() ) {
			$(this).closest( ".input-group" ).addClass('focused');
		}	
	});
	
	
	$(document).on("focus", '.input-group input,.input-group textarea,.input-group select',function(e) {
		$(this).closest( ".input-group" ).addClass('focused');
	});
	$(document).on("blur", '.input-group input,.input-group textarea,.input-group select',function(e) {
		if( !$(this).val() ) {
			$(this).closest( ".input-group" ).removeClass('focused');
		}	
	});
}	


function colorHeaderMenu(){
	//console.log('header menu color');
	var docViewTop = $(window).scrollTop();
	var mainBannerHeight=$('.main_banner ').height()-100;
	var colorMenu=$('.main_banner').data('colorMenu');
	var colorHeader=$('.main_banner').data('colorHeader');
	if($('.main_banner .item').length){
		colorMenu=$('.main_banner .item.active').data('colorMenu') ? $('.main_banner .item.active').data('colorMenu')  : '';
	}
	if($('.main_banner .item').length){
		colorHeader=$('.main_banner .item.active').data('colorHeader') ? $('.main_banner .item.active').data('colorMenu')  :  '';
	}
	if(docViewTop>=mainBannerHeight){
		$('#navbar-collapse-categories a').css('color','');		
		$('#navbar-collapse-main .navbar-right > li > a').css('color','');
		$('.ico-black').removeClass('hidden');
		$('.ico-white').addClass('hidden');
		if($('.cart-red').hasClass('hidden')){
			$('.cart-black').removeClass('hidden');
			$('.cart-white').addClass('hidden');
		}
		$('.navbar-brand').removeClass('hidden');
		$('.navbar-brand.white').addClass('hidden');
	}else{
		if(colorHeader.toUpperCase()=== "#FFF" || colorHeader.toUpperCase()=== "#FFFFFF"){
			$('.ico-white').removeClass('hidden');
			$('.ico-black').addClass('hidden');
			if($('.cart-red').hasClass('hidden')){
				$('.cart-black').addClass('hidden');
				$('.cart-white').removeClass('hidden');
			}
			$('.navbar-brand').addClass('hidden');
			$('.navbar-brand.white').removeClass('hidden');			
		}else{			
			$('.navbar-brand').removeClass('hidden');
			$('.navbar-brand.white').addClass('hidden');
		}
		
		if(colorHeader.toUpperCase()=== "#000" || colorHeader.toUpperCase()=== "#000000"){
			$('.ico-black').removeClass('hidden');
			$('.ico-white').addClass('hidden');
			if($('.cart-red').hasClass('hidden')){
				$('.cart-white').addClass('hidden');
				$('.cart-black').removeClass('hidden');
			}
			$('.navbar-brand').removeClass('hidden');
			$('.navbar-brand.white').addClass('hidden');
		}
		
		$('#navbar-collapse-categories a').css('color',colorMenu);
		$('#navbar-collapse-main .navbar-right > li > a').css('color',colorHeader);
	}
}
function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top+150;
    var elemBottom = elemTop + $(elem).height()-150;
		//ALL VISIBLE
    //return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
		//TOP VISIBLE
		return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
}
function ueImage(){
	//.banner_images img,
	//.double_images img,.category_double_images img
	$('.double_images img,.category_double_images img ').each(function(  ){
		var t=200;
		$(this).not('.ue-done').each(function( ){
			if(isScrolledIntoView($(this))){
					$(this).addClass('ue-done');
				$(this).removeClass('not-visible');
			}else{
				$(this).addClass('not-visible');
			}
		});
	});
}
$(document).ready(function(){
	ueImage();
	colorHeaderMenu();
	$('#mainCarousel').bind('slid.bs.carousel',function(){
       colorHeaderMenu();
    });
});
$(window).scroll(function() {
	ueImage();
	colorHeaderMenu();
});


// Closing the Product page options when the user select them
$( document ).on( 'zg.product.optionUpdated', function(){
	$( '[data-zg-role="option-selector"]' ).find( 'a' ).click( function(){ 
			var collapseMenu = this.closest( '.collapse.in' );
			if( collapseMenu ){
				$( '#' + collapseMenu.id ).collapse( 'toggle' );
			}
	});
});

// ############################################
// ########## VIDEO FULLSCREEN TRICK ##########
// ############################################

function videoSize() {
  var $windowHeight = $(window).height();
  var $videoHeight = $(".video").outerHeight();
	var $scale = $windowHeight / $videoHeight;
  
  if ($videoHeight <= $windowHeight) {
    $(".video").css({
      "-webkit-transform" : "scale("+$scale+") translateY(-50%)",
			"transform" : "scale("+$scale+") translateY(-50%)"
		});
	};
}

$(window).on('load resize',function(){
  videoSize();
});





// ############################################
// ############### PRODUCT ZOOM ###############
// ############################################

var selectedThumbnail = '[data-zg-role="thumbnail-zoom"]';
var containerBig = '[data-zg-role="big-zoom"]';
var openZoomMobile = '[data-zg-role="zoom-open"]';

// Zoom mobile
$(document).on('click', openZoomMobile ,function(){
  var selectedBkg = $(this).attr('data-zoom-images');
	$(containerBig).css('background-image', 'url('+ selectedBkg +')');
	$(containerBig).css('background-position', 'center,center');
	$('.product-zoom').css('display','block');
	$('.product-bar').addClass('up');
});
//

$('#zoomMain img').click(function(e){
	// intercetta l'immgine che va nello zoomMain e apri il product-zoom con quella
	var selectedMain = $(this).attr('src');
	$(containerBig).css('background-image', 'url('+ selectedMain +')');
	$('.product-zoom').css('display','block');
	$('.product-bar').addClass('up');
	$('.table-product').css('display','none');
	$('.suggestedProducts').css('display','none');
	$('.newsletter_block').css('display','none');
	
	//ADD CORRECT SELECTED THUMB
	$('.btn-thumbnail').each(function(){
		if($(this).attr('data-zoom-images')==$('.thumbnail.active').attr('data-images')){
			$(this).trigger('mouseover');
		}
	});
	
});

$('.zoom-close').click(function(e){
	$('.product-zoom').css('display','none');
	$('.product-bar').removeClass('up');
	$('.table-product').css('display','block');
	$('.suggestedProducts').css('display','block');
	$('.newsletter_block').css('display','block');
});

$(document).on('mouseover', selectedThumbnail ,function(){
  var selectedBkg = $(this).attr('data-zoom-images');
	$('.btn-thumbnail').removeClass('selected');
	$(this).addClass('selected');
	$(containerBig).css('background-image', 'url('+ selectedBkg +')');
});


$( document ).on( "mousemove", function( e ) {
	var eventY = e.pageY;
	var docHeight =  $( window ).height();
	var positionY = (eventY / docHeight) * 100;
	$('.zoom-img-container').css('background-position-y', positionY + '%');
});


$(document).on('click', '[data-zg-role="scroll-to"]' ,function(){
  var id = $(this).data('id');
	var classId = $(this).data('class');
	if(classId){
		$('html,body').animate({
    	scrollTop: $('.'+ classId).offset().top-150
  	},'slow');
	}
	if(id){
		$('html,body').animate({
    	scrollTop: $('#'+id).offset().top-150
  	},'slow');
	}
});
$(document).on('focus', '[name="frmEmail"]' ,function(){
	if($('.checkbox.collapse')){
		$('.checkbox.collapse').addClass('in');
	}
});


$(document).on('submit', '[data-zg-role="landing-newsletter"]' ,function(e){
	
	if( $(this).find('#frmEmail').val() == '' || $(this).find('[name="check-page"]').prop('checked') == false){
		
		if($(this).find('#frmEmail').val() == '') $(this).find('#frmEmail').focus();
		
	 return false;
	}
	
});

$(document).on('click','.landing-store [data-zg-role="reset-filters"]',function(e){
	e.preventDefault();
	e.stopPropagation();
	var redir=$('.landing-store').data('redir');
	var country=$('#filter_Country select option:selected').val();
	var city=$('#filter_City select option:selected').val();
	if(country != '' && city != ''){
		location.href=redir+'?Country='+country+'&City='+city;
	}else{
		$('.msg-error').removeClass('hidden');
	}
});
$('.open-footer').on('click',function(){
	/*setTimeout(function(){
		$( "#form_newsletter_modal #frmEmailSub" ).focus();
	},400);*/
	$('html, body').animate({
        scrollTop: $("#frmEmailSub").offset().top+400
    }, 800, function(){$( "#frmEmailSub" ).focus();});
});


const glightbox = GLightbox({
	selector: '.lightbox',
	touchNavigation: false,
	draggable: false,
	keyboardNavigation: false,
	autofocusVideos: true,
	preload: true,
	autoplayVideos: false,
})


/* Menu mobile account Summary */

$(document).ready (function(){
	
	$('.mobile-item').click(function(e){
		e.preventDefault();
		
		if ($(this).hasClass('active')){
			$('.arrow-up').addClass('hidden');
			$('.arrow-down').removeClass('hidden');
			$(this).removeClass('active');
			$(this).children('ul').slideUp();
		} else {
			$('.arrow-up').removeClass('hidden');
			$('.arrow-down').addClass('hidden');
			$('.menu-mobile li ul').slideUp();
			$('.menu-mobile li').removeClass('active');
			$(this).addClass('active');
			$(this).children('ul').slideDown();
			
		}
		
	});

	/*ontrust trick to change lang*/
	var checkLang=0;
	if($('[data-onetrust-lang="IT"]').length != 0){
		$('[data-onetrust-lang="IT"]').html('Cookie Settings');
		$('[data-onetrust-lang="IT"]').on('DOMSubtreeModified', function(){
			if(checkLang == 0){
				checkLang=1;
				$('[data-onetrust-lang="IT"]').html('Cookie Settings');
				$('[data-onetrust-lang="IT"]').off('DOMSubtreeModified');
			}
		});
	}


});

const body = document.body;
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const headerOpen = $('.header-desktop .open')
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
	$(body).removeClass(scrollUp)
    return;
  }

  if (currentScroll > lastScroll && !$(body).hasClass(scrollDown) && headerOpen.length == 0) {
	$(body).removeClass(scrollUp)
	$(body).addClass(scrollDown)
  } else if ( currentScroll < lastScroll && $(body).hasClass(scrollDown)) {
	$(body).addClass(scrollUp)
	$(body).removeClass(scrollDown)
  }

  lastScroll = currentScroll;
});

$(window).on('load', function() {
	if($('[data-zg-role="countrycost"]').length){
		$( '[data-zg-role="countrycost"]' ).each(function( index ) {
			var $form = $( this );
			$form.on("change", 'select' , function() {
				var $selectedValue = $form.find( 'select option:selected' );
				var shippingCost = $selectedValue.data('shipping');
				var returnCost = $selectedValue.data('return');
				var currency = $selectedValue.data('currency');
				$form.find('[data-zg-role="shipping-fee"]').html(JS_TRANSLATIONS.starting_from + " " + currency + " " + shippingCost);
				$form.find('[data-zg-role="return-fee"]').html(currency + " " + returnCost);
			});
		});
		
	};
})