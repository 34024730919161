/**
 *
 * Plugin for Enhanced Ecommerce
 * - 1. Product Impressions
 * - 2. Product Clicks
 * - 3. Add / Remove from Cart
 * - 4. Promotion Impressions
 * - 5. Promotion Clicks
 * What is not included here is present in global/__google_tag_manager.html
 */

(function ( $ ) {
    'use strict';

    var dataLayer = window.dataLayer || [],
        contProductImpressions = 0;

    function parseCategoryNames( categories ){
        var categoryArray = _.map( categories, function( object ){ return object.name;} );
        return categoryArray.join(' / ');
    }

    /**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
        if( product.price ) {
            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });
        }
    } );

    /**
     * 2. Product Clicks
     *
     * To track the link, be sure that all link tag have the attr data-zg-role="product-link"
     */
    $( document ).on( 'click', '[data-zg-role="product-link"]', function(){

        if( this.attributes.href ) {

            var parent = $( this ).closest( '[data-zg-role="product"]' ).data( 'zg.product' );
            var productObj = parent.product;
            var categoryNames = parseCategoryNames( productObj.categories );

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'products': [{
                            'name': productObj.name || '', // Name or ID is required.
                            'id': productObj.id || '',
                            'category': categoryNames || '',
                            'price': productObj.price.sell || ''
                        }]
                    }
                }
            });
        }
    });

/**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
        if( product.price ) {

            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });

            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }
            //console.log(product.price);
            /*GA4 EVENT*/
            var cats =[];
            for(var k in product.categories){
                cats.push(product.categories[k].name)
            }
            var discount =0;
            discount = product.price.to_discount -product.price.sell ;
            if(discount <= 0) {
                discount = 0;
            }
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                items: [{
                    item_id: product.id || '',
                    item_name: product.name || '',
                    affiliation: '',
                    coupon: '',
                    currency: product.price.currency,
                    discount: discount,
                    index: 0,
                    item_brand: product.brand || BRAND, 
                    item_category: cats[0] || '',
                    item_category2: cats[1] || '',
                    item_category3: cats[2] || '',
                    item_category4: cats[3] || '',
                    item_category5: cats[4] || '',
                    item_list_id: list,
                    item_list_name: list,
                    item_variant: '',
                    location_id: '',
                    price: product.price.sell,
                    quantity: 1
                    }]
                }
            });
            /*END GA4 EVENT*/

            

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });
        }
    } );


    /**
     * 3. Add / Remove from Cart
     *
     * Required event: zg.product.addedToCart
     * Required data: status, productObj, dataSent
     */
    $( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': productObj.price.currency,
                'add': { // 'add' actionFieldObject measures.
                    'products': [{ //  adding a product to a shopping cart.
                        'name': productObj.name || '',
                        'id': productObj.id || '',
                        'price': productObj.price.sell || '',
                        'brand': productObj.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
												'category': window.JS_TRANSLATIONS['category.name'],
                        'variant': dataSent.sku || '',
                        'quantity': dataSent.quantity || ''
                    }]
                }
            }
        });
         dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [{
                item_id: productObj.id || '',
                item_name: productObj.name || '',
                affiliation: '',
                coupon: '',
                currency: productObj.price.currency,
                discount: 0,
                index: 0,
                item_brand: productObj.brand || BRAND,
                item_category: window.JS_TRANSLATIONS['category.name'],
                item_list_id: "",
                item_list_name: " ",
                item_variant: "",
                location_id: "",
                price: productObj.price.sell || '',
                quantity: dataSent.quantity || ''
            }]
        }
        });
    } );
    /**
     * Required event: zg.product.removedFromCart
     * Required data: type, status, data
     */
    $( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

        if( type === 'cart' ){
            // Measure the removal of a product from a shopping cart.
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [{ //  removing a product to a shopping cart.
                            'id': data.product_id || '',
                            'variant': data.sku || '',
                            'quantity': data.quantity || ''
                        }]
                    }
                }
            });
        }

    } );

    /**
     * For Cart page
     * When a product is removed from cart we are submitting a form. Also when we submitting a form we should trigger zg.product.removedFromCart
     */
    $( document ).on( 'submit', '[data-zg-role="list-item-remove"]', function () {
        var $this = $(this),
            data  = $this.data();

        var dataToSend = {
            'product_id': 	data.id || 0,
            'sku': 					data.sku || 0,
            'quantity': 		data.quantity || 1
        };

        $( document ).trigger( 'zg.product.removedFromCart', ['cart', null, dataToSend] );
    } );

    /**
     * 4. Promotion Impressions
     *
     * To track the promo present in page, be sure that all promo div have the attr data-zg-role="promotion"
     */
    $( '[data-zg-role="promotion"]' ).each(function( index, element ) {

        dataLayer.push({
            'ecommerce': {
                'promoView': {
                    'promotions': [
                        {
                            'id': element.dataset.promoId || '', // Name or ID is required.
                            'name': element.dataset.promoName || '',
                            'position': element.dataset.promoPosition || ''
                        }]
                }
            }
        });
    });

    /**
     * 5. Promotion Clicks
     *
     * To track the promo link, be sure that all link tag have the attr data-zg-role="promotion-link"
     * Required attr: data-promo-id="x", data-promo-name="x", data-position='x'
     */
    $( document ).on( 'click', '[data-zg-role="promotion-link"]', function() {
        if( this.attributes.href ){
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': this.dataset.promoId || '', // Name or ID is required.
                                'name': this.dataset.promoName || '',
                                'position': this.dataset.promoPosition || ''
                            }]
                    }
                }
            });
        }
    });

}( jQuery ));
